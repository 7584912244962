import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { configureAxios, configureServices } from 'config';
import {
  AxiosInterceptor,
  DelayedLoadingToast,
  FileSystemRoutes,
  JsResizeHelper,
  KeepAlive,
  Modal,
  XsrfToken,
} from 'shared/components';

configureAxios();
configureServices();

function App() {
  return (
    <IntlProvider locale="en">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <JsResizeHelper />
        <Modal.Provider>
          <FileSystemRoutes />
          <KeepAlive />
        </Modal.Provider>
        <XsrfToken />
        <AxiosInterceptor component={DelayedLoadingToast} />
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
