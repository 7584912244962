import React, { ComponentProps, ElementType, ReactHTML } from 'react';
import classNames from 'classnames';

export type StyledElementButtonSolidColors = 'red' | 'indigo';

type AnyElement = ElementType | keyof ReactHTML;
export interface StyledElementButtonSolidProps<T extends AnyElement> {
  tag: T;
  forwardRef: ComponentProps<T>['ref'];
  className?: string;
  color: StyledElementButtonSolidColors;
}

/** styles a tag of type T to look just like a solid button via className */
export default function StyledElementButtonSolid<T extends AnyElement>({
  tag,
  forwardRef,
  className,
  color,
  ...props
}: StyledElementButtonSolidProps<T>) {
  return React.createElement(tag, {
    ...props,
    ref: forwardRef,
    className: classNames(
      className,
      'appearance-none flex justify-center items-center text-center px-4 py-2 text-sm font-medium border-0 rounded-md shadow-sm',
      'focus:outline-none focus:ring-2 focus:ring-offset-2',
      'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500',
      {
        'text-red-900 bg-red-200 hover:bg-red-300 focus:ring-red-300':
          color === 'red',
        'text-white bg-pbpurple-600 hover:bg-pbpurple-700 focus:ring-pbpurple-500':
          color === 'indigo',
      }
    ),
  }) as JSX.Element;
}
