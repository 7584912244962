import { useLocation, useNavigate } from 'react-router';
import qs from 'qs';

const urlParameterName = 'returnUrl';

// TODO: I don't really like this name, thoughts?
export default function useLoginRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const returnUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })[urlParameterName];

  return {
    redirectToLogin: redirectToLogon,
    restoreFromLogin: restoreFromLogin,
  };

  function redirectToLogon(noRedirect: boolean) {
    if (noRedirect) {
      navigate('/login');
    } else {
      navigate(
        '/login?' +
          qs.stringify({
            [urlParameterName]:
              location.pathname + location.search + location.hash,
          })
      );
    }
  }

  function restoreFromLogin() {
    navigate((returnUrl as string) || '/', { replace: true });
  }
}
