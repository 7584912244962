import { ReactNode, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ModalContextProvider } from './ModalContext';

interface ProviderProps {
  children: ReactNode;
}

export default function Provider({ children }: ProviderProps) {
  const [visibleStack, setVisibleStack] = useState<string[]>(['']);

  const addVisible = useCallback((value: string) => {
    if (value === '') {
      return;
    }
    setVisibleStack((state) => [value, ...state]);
  }, []);

  const removeVisible = useCallback((value: string) => {
    if (value === '') {
      return;
    }
    setVisibleStack((state) => state.filter((x) => x !== value));
  }, []);

  const providerValue = useMemo(
    () => ({ openInstanceName: visibleStack[0], addVisible, removeVisible }),
    [visibleStack, addVisible, removeVisible]
  );

  return (
    <ModalContextProvider value={providerValue}>
      {children}
      <div
        className={classNames(
          'fixed inset-0 z-10 transition-opacity bg-gray-500 bg-opacity-50 backdrop-blur-sm',
          {
            'opacity-100': !!visibleStack[0],
            'opacity-0 pointer-events-none': !visibleStack[0],
          }
        )}
      />
    </ModalContextProvider>
  );
}
