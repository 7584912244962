/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCookieExpiration(options: IRequestOptions = {}): Promise<DoubleApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/cookie-expiration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLogin(
    params: {
      /**  */
      provider?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provider: params['provider'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLoginCallback(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      remoteError?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLoginCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'], remoteError: params['remoteError'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      returnUrl?: string;
      /** requestBody */
      body?: RegisterUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeEmail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BodyTraceService {
  /**
   *
   */
  static syncWeight(
    params: {
      /** requestBody */
      body?: SyncWeightCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/bodytrace';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static getBySasToken(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileConfigurationService {
  /**
   *
   */
  static checkForForcedUpdate(
    params: {
      /**  */
      version: string;
      /**  */
      platform: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile-configuration/{version}/{platform}';
      url = url.replace('{version}', params['version'] + '');
      url = url.replace('{platform}', params['platform'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ParticipantsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetParticipantsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParticipantDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantAndWeightsById(
    params: {
      /**  */
      id: number;
      /**  */
      startDateYear?: number;
      /**  */
      startDateMonth?: number;
      /**  */
      startDateDay?: number;
      /**  */
      startDateDayOfWeek?: DayOfWeek;
      /**  */
      startDateDayOfYear?: number;
      /**  */
      startDateDayNumber?: number;
      /**  */
      endDateYear?: number;
      /**  */
      endDateMonth?: number;
      /**  */
      endDateDay?: number;
      /**  */
      endDateDayOfWeek?: DayOfWeek;
      /**  */
      endDateDayOfYear?: number;
      /**  */
      endDateDayNumber?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParticipantDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        'StartDate.Year': params['startDateYear'],
        'StartDate.Month': params['startDateMonth'],
        'StartDate.Day': params['startDateDay'],
        'StartDate.DayOfWeek': params['startDateDayOfWeek'],
        'StartDate.DayOfYear': params['startDateDayOfYear'],
        'StartDate.DayNumber': params['startDateDayNumber'],
        'EndDate.Year': params['endDateYear'],
        'EndDate.Month': params['endDateMonth'],
        'EndDate.Day': params['endDateDay'],
        'EndDate.DayOfWeek': params['endDateDayOfWeek'],
        'EndDate.DayOfYear': params['endDateDayOfYear'],
        'EndDate.DayNumber': params['endDateDayNumber']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteParticipantById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createParticipant(
    params: {
      /** requestBody */
      body?: CreateParticipantCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateParticipant(
    params: {
      /** requestBody */
      body?: UpdateParticipantCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCounselorOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/counselorOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantOptions(
    params: {
      /**  */
      participantId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/participantOptions/{participantId}';
      url = url.replace('{participantId}', params['participantId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScaleOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/scaleOptions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPercentOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/percentOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ScalesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScaleDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScaleById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScaleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteScaleById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createScale(
    params: {
      /** requestBody */
      body?: CreateScaleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateScale(
    params: {
      /** requestBody */
      body?: UpdateScaleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettingByKey(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUserNoPhoto(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUser(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lockUser(
    params: {
      /** requestBody */
      body?: LockUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/lock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlockUser(
    params: {
      /** requestBody */
      body?: UnlockUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/unlock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WeightsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetWeightsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WeightDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getWeightById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WeightDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteWeightById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createWeight(
    params: {
      /** requestBody */
      body?: CreateWeightCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateWeight(
    params: {
      /** requestBody */
      body?: UpdateWeightCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static syncWeights(
    params: {
      /** requestBody */
      body?: SyncWeightsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/sync';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  result?: AccountConfirmationResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AnnotationPointDto {
  /**  */
  x?: number;

  /**  */
  y?: number;

  /**  */
  marker?: Marker;
}

export interface AuthenticationProperties {
  /**  */
  items?: object;

  /**  */
  parameters?: object;

  /**  */
  isPersistent?: boolean;

  /**  */
  redirectUri?: string;

  /**  */
  issuedUtc?: string;

  /**  */
  expiresUtc?: string;

  /**  */
  allowRefresh?: boolean;
}

export interface BooleanApiResult {
  /**  */
  result?: boolean;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ChallengeResult {
  /**  */
  authenticationSchemes?: string[];

  /**  */
  properties?: AuthenticationProperties;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface CreateParticipantCommand {
  /**  */
  penningtonId?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: DateOnly;

  /**  */
  studyEndDate?: DateOnly;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  goalWeight?: number;

  /**  */
  baselineEnergyIntake?: number;

  /**  */
  newEnergyIntake?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  percentType?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  userId?: number;

  /**  */
  scaleId?: number;
}

export interface CreateScaleCommand {
  /**  */
  name?: string;

  /**  */
  scaleImei?: string;
}

export interface CreateUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  userRole?: number;
}

export interface CreateWeightCommand {
  /**  */
  kilograms?: number;

  /**  */
  pounds?: number;

  /**  */
  createdDate?: string;

  /**  */
  participantId?: number;

  /**  */
  useLbs?: boolean;
}

export interface DataPointDto {
  /**  */
  x?: DateOnly;

  /**  */
  y?: number;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface DateOnly {
  /**  */
  year?: number;

  /**  */
  month?: number;

  /**  */
  day?: number;

  /**  */
  dayOfWeek?: DayOfWeek;

  /**  */
  dayOfYear?: number;

  /**  */
  dayNumber?: number;
}

export interface DoubleApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ForgotPasswordCommand {
  /**  */
  email?: string;
}

export interface GetParticipantsRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetWeightsRequest {
  /**  */
  participantId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GraphDto {
  /**  */
  series?: SeriesDto[];

  /**  */
  annotationPoints?: AnnotationPointDto[];

  /**  */
  trafficLightColor?: string;
}

export interface LockUserCommand {
  /**  */
  email?: string;
}

export interface Marker {
  /**  */
  fillColor?: string;

  /**  */
  strokeColor?: string;

  /**  */
  size?: number;

  /**  */
  shape?: string;
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface OptionItemDtoListApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParticipantDto {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: DateOnly;

  /**  */
  studyEndDate?: DateOnly;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  goalWeight?: number;

  /**  */
  baselineEnergyIntake?: number;

  /**  */
  newEnergyIntake?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  percentType?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  counselorEmail?: string;

  /**  */
  scaleName?: string;

  /**  */
  trafficLightIndicator?: string;

  /**  */
  latestWeight?: number;

  /**  */
  latestWeighIn?: string;

  /**  */
  userId?: number;

  /**  */
  scaleId?: number;

  /**  */
  graphData?: GraphDto;
}

export interface ParticipantDtoApiResult {
  /**  */
  result?: ParticipantDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParticipantDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ParticipantDto[];
}

export interface ParticipantDtoDataTablePageApiResult {
  /**  */
  result?: ParticipantDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PasswordLoginCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface RegisterUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ResetPasswordCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface ScaleDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  scaleImei?: string;

  /**  */
  participant?: string;
}

export interface ScaleDtoApiResult {
  /**  */
  result?: ScaleDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ScaleDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScaleDto[];
}

export interface ScaleDtoDataTablePageApiResult {
  /**  */
  result?: ScaleDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  email?: string;
}

export interface SeriesDto {
  /**  */
  name?: string;

  /**  */
  type?: string;

  /**  */
  color?: string;

  /**  */
  data?: DataPointDto[];
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingDtoApiResult {
  /**  */
  result?: SettingDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  result?: SettingDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  result?: StatusResult[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringApiResult {
  /**  */
  result?: string;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface StringOptionItemDtoListApiResult {
  /**  */
  result?: StringOptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SyncWeightCommand {
  /**  */
  imei?: string;

  /**  */
  ts?: number;

  /**  */
  values?: Values;
}

export interface SyncWeightsCommand {
  /**  */
  participantId?: number;
}

export interface UnlockUserCommand {
  /**  */
  email?: string;
}

export interface UpdateParticipantCommand {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: DateOnly;

  /**  */
  studyEndDate?: DateOnly;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  goalWeight?: number;

  /**  */
  baselineEnergyIntake?: number;

  /**  */
  newEnergyIntake?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  percentType?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  userId?: number;

  /**  */
  scaleId?: number;
}

export interface UpdateScaleCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  scaleImei?: string;
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  userRole?: number;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface UpdateWeightCommand {
  /**  */
  id?: number;

  /**  */
  kilograms?: number;

  /**  */
  pounds?: number;

  /**  */
  createdDate?: string;

  /**  */
  participantId?: number;

  /**  */
  useLbs?: boolean;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  userRole?: number;

  /**  */
  userRoleName?: string;
}

export interface UserDtoApiResult {
  /**  */
  result?: UserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserListItemDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  userRoleName?: string;

  /**  */
  isLockedOut?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface Values {
  /**  */
  weight?: number;
}

export interface WeightDto {
  /**  */
  id?: number;

  /**  */
  kilograms?: number;

  /**  */
  pounds?: number;

  /**  */
  participant?: string;

  /**  */
  scale?: string;

  /**  */
  createdDate?: string;

  /**  */
  manuallyModified?: boolean;

  /**  */
  participantId?: number;

  /**  */
  useLbs?: boolean;
}

export interface WeightDtoApiResult {
  /**  */
  result?: WeightDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface WeightDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: WeightDto[];
}

export interface WeightDtoDataTablePageApiResult {
  /**  */
  result?: WeightDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export enum DayOfWeek {
  'Sunday' = 'Sunday',
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday'
}

export enum PercentType {
  'PercentWeightLoss' = 'PercentWeightLoss',
  'PercentEnergyRestriction' = 'PercentEnergyRestriction'
}
