import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import StyledElementButtonSolid, { StyledElementButtonSolidColors } from './StyledElementButtonSolid';

export interface ButtonSolidProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: StyledElementButtonSolidColors;
}

/** A button with a solid fill of color */
const ButtonSolidRef = forwardRef(ButtonSolid) as (
  // We are using a type assertion here to make the type more clear rather than exotic components and such
  // see: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
  {
    className,
    color,
    children,
    ...props
  }: ButtonSolidProps & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof ButtonSolid>;

export default ButtonSolidRef;

function ButtonSolid(
  props: ButtonSolidProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return <StyledElementButtonSolid {...props} forwardRef={ref} tag="button" />;
}
