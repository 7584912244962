import { ReactNode } from 'react';
import ShadowScroll from '../ShadowScroll';

export interface BodyProps {
  children: ReactNode;
}

export default function Body({ children }: BodyProps) {
  return (
    <ShadowScroll className="flex overflow-hidden border border-gray-100">
      <ShadowScroll.ScrollArea className="flex flex-col flex-1 p-4 overflow-auto">
        {children}
      </ShadowScroll.ScrollArea>
    </ShadowScroll>
  );
}
