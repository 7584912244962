import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

declare module 'axios' {
  export interface AxiosRequestConfig {
    hasLoadingMessage?: boolean;
  }
}

export const loadUser = createAsyncThunk('auth/loadUser', async () => {
  const response = await axios.get('/api/account/me', {
    hasLoadingMessage: true,
  });
  const data = response.data;
  const { result } = (!!data && data) || {};
  return result;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await axios.post('/api/account/logout', {
    hasLoadingMessage: true,
  });
  // need to re-get a new Xsrf - they are tied to user
  await axios.get('/api/account/me', {
    hasLoadingMessage: true,
  });
});

export const getCookieExpiration = createAsyncThunk(
  'auth/cookieExpiration',
  async () => {
    const response = await axios.get('/api/account/cookie-expiration', {
      hasLoadingMessage: true,
    });
    const data = response.data;
    const { result } = (!!data && data) || 0;
    return result;
  }
);

export const setNoAuth = createAction('auth/setNoAuth');

export const allowRedirects = createAction('auth/allowRedirects');
