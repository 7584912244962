import { ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import StrongTo from './StrongTo';

export interface StrongLinkProps extends LinkProps {
  to: StrongTo;
}

const StrongLinkRef = forwardRef(StrongLink) as (
  props: StrongLinkProps & React.RefAttributes<HTMLAnchorElement>
) => ReturnType<typeof StrongLink>;

export default StrongLinkRef;

function StrongLink(
  { children, to, ...props }: StrongLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Link {...props} ref={ref} to={to}>
      {children}
    </Link>
  );
}
