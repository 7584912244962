// this is auto generated, don't try and change it
const routes = [
  {importPath: './src/routes/403.tsx', path: '403', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/403')},
  {importPath: './src/routes/404.tsx', path: '404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/404')},
  {importPath: './src/routes/Admin/_Layout.tsx', path: 'Admin/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/_Layout')},
  {importPath: './src/routes/Admin/404.tsx', path: 'Admin/404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/404')},
  {importPath: './src/routes/Admin/index.tsx', path: 'Admin/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/index')},
  {importPath: './src/routes/Admin/Participants~.tsx', path: 'Admin/Participants~', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Participants~')},
  {importPath: './src/routes/Admin/Scales~.tsx', path: 'Admin/Scales~', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Scales~')},
  {importPath: './src/routes/Admin/Settings~.tsx', path: 'Admin/Settings~', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Settings~')},
  {importPath: './src/routes/Admin/Users~.tsx', path: 'Admin/Users~', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Users~')},
  {importPath: './src/routes/Admin/Weights~.tsx', path: 'Admin/Weights~', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "admin" */'./routes/Admin/Weights~')},
  {importPath: './src/routes/ChangePassword.tsx', path: 'ChangePassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ChangePassword')},
  {importPath: './src/routes/ConfirmAccount.tsx', path: 'ConfirmAccount', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ConfirmAccount')},
  {importPath: './src/routes/ForgotPassword.tsx', path: 'ForgotPassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ForgotPassword')},
  {importPath: './src/routes/ForgotPasswordSuccess.tsx', path: 'ForgotPasswordSuccess', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ForgotPasswordSuccess')},
  {importPath: './src/routes/index.tsx', path: 'index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/index')},
  {importPath: './src/routes/Login.tsx', path: 'Login', importFunc: () => import(/* webpackMode: "eager" *//* webpackChunkName: "login" */'./routes/Login')},
  {importPath: './src/routes/Participant/_Layout.tsx', path: 'Participant/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Participant/_Layout')},
  {importPath: './src/routes/Participant/Dashboard~.tsx', path: 'Participant/Dashboard~', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Participant/Dashboard~')},
  {importPath: './src/routes/ResetPassword.tsx', path: 'ResetPassword', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ResetPassword')},
  {importPath: './src/routes/ResetPasswordConfirmation.tsx', path: 'ResetPasswordConfirmation', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/ResetPasswordConfirmation')},
];

export default routes;
export type StrongRouteTypes =  | `/403` | `/404` | `/admin/404` | `/admin` | `/admin/participants` | `/admin/participants/${string}` | `/admin/scales` | `/admin/scales/${string}` | `/admin/settings` | `/admin/settings/${string}` | `/admin/users` | `/admin/users/${string}` | `/admin/weights` | `/admin/weights/${string}` | `/change-password` | `/confirm-account` | `/forgot-password` | `/forgot-password-success` | `/` | `/login` | `/participant/dashboard` | `/participant/dashboard/${string}` | `/reset-password` | `/reset-password-confirmation`;
export const routeMap = {
	'403': () : StrongRouteTypes => `/403`,
	'404': () : StrongRouteTypes => `/404`,
	'Admin/404': () : StrongRouteTypes => `/admin/404`,
	'Admin/index': () : StrongRouteTypes => `/admin`,
	'Admin/Participants~': (suffix?: string | undefined) : StrongRouteTypes => {
		if (suffix) return `/admin/participants/${suffix}`;
		else return `/admin/participants`;
},	'Admin/Scales~': (suffix?: string | undefined) : StrongRouteTypes => {
		if (suffix) return `/admin/scales/${suffix}`;
		else return `/admin/scales`;
},	'Admin/Settings~': (suffix?: string | undefined) : StrongRouteTypes => {
		if (suffix) return `/admin/settings/${suffix}`;
		else return `/admin/settings`;
},	'Admin/Users~': (suffix?: string | undefined) : StrongRouteTypes => {
		if (suffix) return `/admin/users/${suffix}`;
		else return `/admin/users`;
},	'Admin/Weights~': (suffix?: string | undefined) : StrongRouteTypes => {
		if (suffix) return `/admin/weights/${suffix}`;
		else return `/admin/weights`;
},	'ChangePassword': () : StrongRouteTypes => `/change-password`,
	'ConfirmAccount': () : StrongRouteTypes => `/confirm-account`,
	'ForgotPassword': () : StrongRouteTypes => `/forgot-password`,
	'ForgotPasswordSuccess': () : StrongRouteTypes => `/forgot-password-success`,
	'index': () : StrongRouteTypes => `/`,
	'Login': () : StrongRouteTypes => `/login`,
	'Participant/Dashboard~': (suffix?: string | undefined) : StrongRouteTypes => {
		if (suffix) return `/participant/dashboard/${suffix}`;
		else return `/participant/dashboard`;
},	'ResetPassword': () : StrongRouteTypes => `/reset-password`,
	'ResetPasswordConfirmation': () : StrongRouteTypes => `/reset-password-confirmation`,
};
