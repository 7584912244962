import React, { ReactNode, Suspense, useMemo } from 'react';
import { Outlet, useRoutes } from 'react-router';
import { NavigationPathConverter } from 'NavigationPathConverter.js';
import routeFiles from 'route-files';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { RouteObject } from 'react-router-dom';
import { createStrictContext } from 'envoc-strict-context';

// add extra information so we can traverse up and down the file system routes using useLeafContext
export interface RouteObjectWithParent extends RouteObject {
  parent: RouteObjectWithParent | null;

  /**
   * The full path that rendered this route.
   * Starts with a forward slash '/' and should resolve to the desired page if used as the "to" parameter of a react-router navigate
   * Will never contain a "*"
   */
  fullPath: string;
}

/**
 * The useLeafContext hook allows access to the route config such that a leaf element can fetch it's own config or any of its progenitors
 * @returns The matched MatchedLeafContext from FileSystemRoutes
 * @throws If not used within the context of a FileSystemRoutes
 */
export const [MatchedLeafContextProvider, useLeafContext] =
  createStrictContext<RouteObjectWithParent>({
    errorMessage:
      'Missing MatchedLeafContext - this should only be used within the context of a FileSystemRoutes component',
    name: 'MatchedLeafContext',
  });

/**
 * Creates a tree of routes based on the file and folder layout with src/routes
 * Each file in any folder at or below src/routes should render a default component that will be rendered when matching
 * The path to that component in the filesystem will represent the
 * @returns
 */
export default function FileSystemRoutes() {
  const result = useMemo(() => {
    const rootRoutes = [] as RouteObjectWithParent[];
    let RootLayout = null as ReactNode | null;

    for (let index = 0; index < routeFiles.length; index++) {
      const element = routeFiles[index];
      const Component = React.lazy(element.importFunc as any);

      let parent = null as RouteObjectWithParent | null;

      // take each part in a path and ensure we have a route for each name
      // this way we can handle folder routes that have no layout
      const parts = element.path.split('/');
      for (let index = 0; index < parts.length; index++) {
        const part = parts[index];

        const effectivePath = NavigationPathConverter(part);

        // special note: we assume no folder will ever be called _Layout
        if (part === '_Layout') {
          // this should wrap all the children, including index routes - there should exist only a single _Layout instance per path part (e.g. folder)
          const LayoutElement = (
            <Suspense fallback={<BlockingLoader />}>
              <Component />
            </Suspense>
          );

          if (parent === null) {
            RootLayout = LayoutElement;
          } else {
            parent.element = LayoutElement;
          }
        } else if (index === parts.length - 1) {
          const leafConfig = {
            parent: parent,
          } as RouteObjectWithParent;

          if (effectivePath === 'index') {
            leafConfig.fullPath = (parent?.fullPath ?? '') + '/';
            leafConfig.index = true;
          } else if (effectivePath === '404') {
            leafConfig.fullPath = (parent?.fullPath ?? '') + '/NotFound';
            // only the last character may be '*' in react router 6
            // see: https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-route-path-patterns
            leafConfig.path = '*';
          } else {
            leafConfig.fullPath =
              (parent?.fullPath ?? '') + '/' + effectivePath.replace('~', '');
            // we allow any Leaf element to render any child routes they wish
            // The only downside is that in such a case we cannot show a 404 page on a partial match
            // e.g. if we have a /Foo component
            leafConfig.path =
              effectivePath === '*' ? '*' : effectivePath.replace('~', '/*');
          }

          // this is the final element in question in the path
          const Leaf = (
            <MatchedLeafContextProvider value={leafConfig}>
              <Suspense fallback={<BlockingLoader />}>
                <Component />
              </Suspense>
            </MatchedLeafContextProvider>
          );
          leafConfig.element = Leaf;

          if (parent === null) {
            rootRoutes.push(leafConfig);
          } else {
            if (!parent.children) {
              parent.children = [];
            }
            parent.children.push(leafConfig);
          }
        } else {
          // we still have folders to parse, keep going deeper
          let parentPool = [] as RouteObjectWithParent[];
          if (!parent) {
            parentPool = rootRoutes;
          } else {
            if (!parent.children) {
              parent.children = [];
            }
            parentPool = parent.children as RouteObjectWithParent[];
          }
          const existingParent = parentPool.find(
            (x) => x.path === effectivePath
          );
          if (!existingParent) {
            const parentConfig = {
              parent: parent,
              fullPath: (parent?.fullPath ?? '') + '/' + effectivePath,
              path: effectivePath,
              element: <Outlet />,
            } as RouteObjectWithParent;
            parentPool.push(parentConfig);
            parent = parentConfig;
          } else {
            parent = existingParent;
          }
        }
      }
    }
    if (!RootLayout) {
      return rootRoutes;
    }
    return [
      {
        path: '/',
        element: RootLayout,
        children: rootRoutes,
      },
    ];
  }, []);

  return useRoutes(result);
}

function BlockingLoader() {
  return (
    <>
      <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-screen h-screen gap-2 text-gray-600 bg-white">
        <div>Loading Content</div>
        <FontAwesomeIcon
          icon={faSpinner}
          className="!w-12 !h-12 animate-spin"
        />
      </div>
      <div className="hidden">
        <Outlet />
      </div>
    </>
  );
}
